<template>
  <div>
    <h3 class="news-add-title">新增新闻</h3>
    <Form ref="form" :model="form" label-width="100px">
      <FormItem label="封面图：">
        <Row>
          <Col :span="4">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
      </FormItem>
      <FormItem label="大图：">
        <Row>
          <Col :span="4">
            <UploadImgAndVideo></UploadImgAndVideo>
          </Col>
        </Row>
      </FormItem>
      <FormItem label="内容：">
        <Input
          type="textarea"
          :rows="4"
          placeholder="请输入新闻内容"
          v-model="form.biaoyu1"
        />
      </FormItem>
      <FormItem label="">
        <Button class="submit-btn" size="medium" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>
    <script>
import { Form, FormItem, Input, Row, Col, Button } from "element-ui";
import UploadImgAndVideo from "../components/UploadImgAndVideo.vue";
export default {
  components: {
    Form,
    FormItem,
    UploadImgAndVideo,
    Input,
    Row,
    Col,
    Button,
  },
  data() {
    return {
      form: {
        biaoyu1: "",
      },
      video1:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/shouye123.mp4",
      video2:
        "https://qizhixiaofu.oss-cn-hangzhou.aliyuncs.com/videos/pinpaizeren.mp4",
    };
  },
  methods: {
    delVideo(count) {
      if (count == 1) {
        this.video1 = "";
      }
      if (count == 2) {
        this.video2 = "";
      }
    },
  },
};
</script>
<style lang="scss">
.video-content {
  position: relative;
  width: 300px;
  height: 200px;
  border: 1px solid #efefef;
  margin-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .video-content-desc {
    color: #efefef;
  }
  .qs-video {
    width: 100%;
    max-height: 200px;
  }
  .el-icon-circle-close {
    position: absolute;
    right: -10px;
    top: -10px;
    font-size: 20px;
    color: red;
    z-index: 100;
    cursor: pointer;
  }
}
.mt-10 {
  margin-top: 10px;
}
.welfare-subtitle {
  width: 92px;
  &.color-white {
    color: white;
  }
}
.submit-btn,
.submit-btn:hover,
.submit-btn:focus {
  width: 80px;
  background-color: rgb(157, 30, 30);
  border-color: rgb(157, 30, 30);
}
.news-add-title {
  margin-bottom: 50px;
}
</style>
    